<template>
  <div class="interview-third-step--container" :class="{'alerted' : isAlert}">
    <div class="interview--container">
      <h1>Карточка родителя</h1>
      <h3>Введите полные фамилию и имя</h3>
      <div class="alert--container" v-if="isAlert">
        <alert-text :value="alertText"></alert-text>
      </div>
      <div class="input--container">
        <material-input
            id="firstName"
            type="text"
            label="ИМЯ"
            v-model="firstName"
            :is-trim-enable="false"></material-input>
      </div>
      <div class="input--container">
        <material-input
            id="lastName"
            type="text"
            label="ФАМИЛИЯ"
            v-model="lastName"
            :is-trim-enable="false"></material-input>
      </div>
      <div class="checkbox--container">
        <div class="title--container">
          <p :class="{'selected' : parentStatus.length > 0}">ВЫ ДЛЯ РЕБЕНКА:</p>
        </div>
        <div class="checkboxes--container">
          <input type="radio"  name="radiogender" id="checkboxOne" value="папа" v-model="parentStatus"><label for="checkboxOne">ПАПА</label>
          <input type="radio" name="radiogender" id="checkboxTwo" value="мама" v-model="parentStatus"><label for="checkboxTwo">МАМА</label>
          <input type="radio" name="radiogender" id="checkboxThree" value="другое" v-model="parentStatus"><label for="checkboxThree">ДРУГОЕ</label>
        </div>
      </div>
      <div class="buttons--container">
        <material-button
            color="white-circle"
            size="xs"
            value="НАЗАД"
            @click.native="$emit('previous')"></material-button>
        <material-button
            color="pink-gradient"
            size="xs"
            value="ДАЛЕЕ"
            @click.native="$emit('next',firstName,lastName,parentStatus)"></material-button>
      </div>
    </div>
    <div class="support--container">
      <div class="info--container">
        <div class="avatar--container">
          <img class="avatar__img" src="../../assets/img/ava.374665b0.svg" draggable="false">
        </div>
        <div class="text--container">
          <p class="name__text">Анна</p>
          <p class="work__text">Специалист</p>
        </div>
      </div>
      <div class="chat--list-container">
        <div class="chat--list-item">
          <p> Данные будут проверяться на соответствие. В случае проблем с верификацией, мы свяжемся с вами</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertText from "../alert/AlertText";
import MaterialInput from "../input/MaterialInput";
import MaterialButton from "../button/MaterialButton";
export default {
  name: "SecondStepContainer",
  components: {MaterialButton, MaterialInput, AlertText},
  props:{
    alertText: String,
    isAlert: Boolean,
  },
  data(){
    return {
      firstName: "",
      lastName: "",
      parentStatus: ""
    }
  },
}
</script>

<style scoped lang="scss">
.interview-third-step--container{
  width: 620px;
  height: 480px;
  background: #FFFFFF;
  box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);
  display: flex;

  &.alerted{
    height: 540px;
  }

  .interview--container h1{
    margin-top: 57px;
  }

  @include for-mobile-devices{
    width: 350px;
    height: 520px;


    &.alerted{
      height: 580px;
    }
  }
}

.interview--container{
  width: calc(420px - 50px);
  margin-left: 50px;
  height: 100%;

  @include for-mobile-devices{
    margin-left: 35px;
  }

  .skip__text{
    font-family: Roboto, "sans-serif";
    font-size: 14px;
    color: #000000;
    text-align: center;
    text-shadow: 0 2px 4px rgba(0,0,0,0.12);
    width: 307px;
    margin-top: 23px;
    cursor: pointer;

    @include for-mobile-devices{
      display: none;
    }
  }

  .info--container{
    margin-top: 38px;
    display: flex;

    .avatar--container{
      .outer--gradient{
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-image: linear-gradient(-180deg, rgba(255,255,255,0.20) 0%, rgba(255,255,255,0.00) 100%);
        box-shadow: 0 1px 5px 0 rgba(0,0,0,0.20), 0 1px 2px 0 rgba(0,0,0,0.20), inset 0 1px 0 0 rgba(255,255,255,0.16), inset 0 -1px 0 0 rgba(0,0,0,0.05);
        .inner--gradient{
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-image: linear-gradient(-180deg, rgba(255,255,255,0.40) 0%, rgba(255,255,255,0.20) 100%);
          box-shadow: 0 1px 5px 0 rgba(0,0,0,0.20), 0 1px 2px 0 rgba(0,0,0,0.20), inset 0 1px 0 0 rgba(255,255,255,0.16), inset 0 -1px 0 0 rgba(0,0,0,0.05);

          .avatar__img{
            width: 46px;
            height: 46px;
            border-radius: 50%;
          }
        }
      }
    }

    .text--container{
      margin-top: 3px;
      margin-left: 10px;
      .name__text{
        font-family: Roboto, "sans-serif";
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .class__text{
        background: #F4F4F4;
        font-family: Roboto, "sans-serif";
        font-size: 14px;
        color: #222222;
        letter-spacing: 0;
        text-align: center;
        text-shadow: 0 0 0 rgba(0,0,0,0.50);
        border-radius: 13px;
        height: 26px;
        margin-top: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96px;

        pointer-events: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
      }
    }
  }

  h1{
    font-family: Roboto, "sans-serif";
    font-size: 24px;
    color: #000000;
    line-height: 21px;
    letter-spacing: 0;
    margin-top: 25px;
  }

  h2{
    font-family: Roboto, "sans-serif";
    font-weight: 300;
    font-size: 16px;
    color: #000000;
    line-height: 21px;
    margin-top: 38px;
    width: 324px;
    margin-bottom: 5px;

    @include for-mobile-devices{
      width: 270px;
      font-size: 13px;
    }
  }

  h3{
    font-family: Roboto, "sans-serif";
    font-weight: 300;
    letter-spacing: 0;
    font-size: 13px;
    color: #000000;
    line-height: 17px;
    margin-top: 5px;
    width: 280px;
  }

  .input--container{
    display: flex;
    margin-top: 23px;
  }

  .buttons--container{
    margin-top: 65px;
    width: 311px;
    display: flex;
    justify-content: space-between;

    @include for-mobile-devices{
      flex-direction: column-reverse;
      width: 244px;
      margin-top: 63px;
      justify-content: flex-start;
      margin-left: 14px;
      margin-right: 53px;


      button:first-child{
        margin-top: 20px;
      }

      button:last-child{
        width: 100%;
      }
    }
  }

  .checkbox--container{
    margin-top: 22px;

    .title--container{
      p{
        color: #C6C6C6;
        font-size: 12px;
        transition: 300ms ease all;


        &.selected{
          color: #F54E5E;
        }
      }
    }

    .checkboxes--container{
      margin-top: 18px;
      display: flex;

      @include for-mobile-devices{
        margin-left: -19px;
      }
    }
  }
}

.support--container{
  width: 200px;
  height: 100%;
  background: #F5F5F5;
  border: 0 solid #F2F2F2;

  @include for-mobile-devices{
    display: none;
  }

  .info--container{
    margin-top: 38px;
    margin-left: 10px;
    display: flex;

    .avatar--container{
      .avatar__img{
        width: 60px;
        height: 60px;
      }
    }

    .text--container{
      margin-left: 17px;
      margin-top: 3px;

      .name__text{
        font-family: Roboto, "sans-serif";
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        pointer-events: none;
      }

      .work__text{
        font-family: Roboto, "sans-serif";
        font-size: 11px;
        color: #9B9B9B;
        letter-spacing: 0;
        margin-top: 7px;
        pointer-events: none;
      }
    }
  }

  .chat--list-container{
    margin-left: 10px;
    margin-top: 18px;

    .chat--list-item{
      background: #EBEBEB;
      border-radius: 4px;
      width: 180px;

      p{
        padding: 12px 8px 12px 14px;
        font-family: Roboto, "sans-serif";
        font-weight: 300;
        font-size: 12px;
        color: #000000;
        letter-spacing: 0;
        line-height: 15px;
      }
    }
  }
}

label{
  display: inline-block;
  background-color: white;
  border-radius: 25px;
  white-space: nowrap;
  margin-right: 9px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s;
  font-size: 14px;
  color: #141213;
  text-align: center;
  font-family: Roboto, "sans-serif";
  font-weight: 300;
  border: 1px solid #E7E7E7;
}


label:hover{
  background-color: rgba(155,155,155,0.05);
}


label {
  width: 100px;
  height: 36px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}




input[type="radio"]:checked + label {
  transition: all .2s;
  border: 0;
  background: rgba(155,155,155,0.10);
}

input[type="radio"] {

}
input[type="radio"] {
  position: absolute;
  opacity: 0;
}



.support--container *{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.checkboxes--container{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
